@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../css/color.scss'; // Importing color variables and mixins
@import '../css/fonts.scss'; // Importing font styles and mixins

$grid-gutter-width: 2rem !default;

:root {
  --bs-body-font-family: var(--font-family) !important;
}
body {
  font-family: var(--font-family) !important;
  font-synthesis: none;
}
/* Visually hide the skip link */
.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  padding: 8px;
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s;
  width: 100%;
}

/* Show the skip link when it receives focus */
.skip-link:focus {
  top: 0;
  opacity: 1;
}
/* Visually hide the skip link */

.font_fourty {
  font-size: 2.5rem;
}

.text-decoration-underline {
  text-underline-position: under;
}

.link-text {
  text-decoration: none;
}

.link-text:hover {
  text-decoration: underline;
}

.enroll-label-wid {
  width: 10rem;
}

.let-space-text {
  letter-spacing: 0.02rem;
}

.link-blue {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link-blue li {
  border-bottom: 1px solid $sweet-cream;
}

.link-blue li:last-child {
  border-bottom: 0;
}

.link-blue li a {
  padding-right: 1rem;
  position: relative;
}

.link-blue li a::after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -4px;
  content: '';
  @extend .arrow-right;
}

.arrow-right,
.arrow-left {
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid $primary;
  border-left: 2px solid $primary;
  transform: rotate(135deg);
}

.arrow-left {
  transform: rotate(-45deg);
}

.arrow-back {
  position: relative;
  padding-left: 1.25rem;
}

.arrow-back::after {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -0.25rem;
  content: '';
  @extend .arrow-left;
}

.arrow-back-black {
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid $secondary;
  border-left: 2px solid $secondary;
  transform: rotate(-45deg);
  margin-right: 10px;
}

.arrow-back-pharmacy {
  @media screen and (min-width: 768px) {
    width: 0.7rem !important;
  }
}

.check-box-only .form-check {
  padding-left: 2.25rem;
}

.container {
  max-width: 1280px;
}
.table-overflow-hidden {
  overflow: hidden;
}
.table-custom-style th {
  vertical-align: top;
}

.btn-medium {
  min-height: 40px !important;
  padding: 6px 20px 7px !important;
}

/* button primary custom style */
input::placeholder {
  color: $granite-gray;
}

.pos-rel {
  position: relative;
}

.border-clr {
  border-color: $very-light-gray !important;
}

.border-w-2 {
  border-width: 2px;
}

.marg_thirtytwo {
  margin-right: 32px;
}
textarea.form-control,
textarea.form-input {
  min-height: 6rem;
  max-height: inherit;
}

.error-input-bdr {
  border-color: $red !important;
}
.flex-1 {
  flex: 1 1 auto;
}

.dob-field .form-input {
  padding-left: 40px;
}

.your-question .form-input {
  height: auto;
}

.date-icon-custom {
  position: absolute;
  top: 6px;
  left: 15px;
}

.dob-field .react-calendar {
  position: absolute;
  z-index: 2 !important;
  top: auto;
  left: 0;
}

.dob-field .react-calendar * {
  text-decoration: none !important;
}

.mw-auto {
  min-width: auto !important;
}

.signin-banner-img {
  width: 100%;
  position: relative;
  right: -40px;
}

.signin-btn-header {
  padding: 7px 16px 7px 12px !important;
  min-width: auto !important;
  height: 40px !important;
}

.search-input-header,
.search-input-header svg {
  position: relative;
}

.search-input-header svg {
  left: 20px;
}

.search-input-header input {
  box-shadow: none;
  width: 96px;
  padding: 8px 0 8px 27px;
}

.search-input-header input::placeholder {
  color: $dark-grayish;
  font-family: var(--font-family-medium), sans-serif;
  font-weight: 700;
}

.singin-input .eye_icon {
  min-width: auto;
  border-radius: 0.313em;
}

.singin-input .eye_icon,
.eye_icon {
  top: 6px !important;
  outline-width: 0.125rem !important;
}
.hamburger-menu-overlay {
  background-color: #fff;
  border-radius: 16px 0 0 16px;
  max-width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1),
    0px 4px 6px -4px rgba(16, 24, 40, 0.1);
  animation: 1s hamburger-slide-on;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 304px;
}

@keyframes hamburger-slide-on {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

@keyframes hamburger-slide-close {
  from {
    margin-left: 0%;
  }

  to {
    margin-left: 100%;
  }
}

.hamburger-nav-one,
.hamburger-nav-two {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hamburger-nav-one li {
  margin-bottom: 17px;
}

.hamburger-nav-one li a,
.hamburger-nav-two li a,
.hamburger-nav-two li button {
  display: block;
  text-decoration: none;
}

.hamburger-nav-one li a:hover,
.hamburger-nav-one li a:focus {
  text-decoration: underline;
}

.hamburger-nav-two li a,
.hamburger-nav-two li button {
  border-left: 4px solid transparent;
  font-family: var(--font-family-bold), sans-serif;
  font-size: 1rem;
  font-weight: 700;
  padding: 12px 12px 12px 16px;
  color: $dark-grayish;
}

.hamburger-nav-two li a.active,
.hamburger-nav-two li button.active {
  border-left: 4px solid #0e406a;
}
.accordion-hamburgermenu .accordion-menu-item .accordion-button {
  color: $dark-grayish;
  padding: 0.875rem 1.5rem 0.875rem 0.875rem;
  font-size: 1rem;
}

// Password strength

.container_bar {
  margin: 10px 0px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.strength-bar-container {
  margin-top: 10px;
  width: 100%;
  height: 4px;
  background-color: #ddd;
}

.strength-bar-weak {
  background-color: #ef4444;
  height: 4px;
  width: 33%;
  @media (max-width: 520px) {
    width: 20%;
  }
}

.strength-bar-medium {
  background-color: #f3bd48;
  height: 4px;
  width: 66%;
  @media (max-width: 520px) {
    width: 46%;
  }
}

.strength-bar-strong {
  background-color: #22c55e;
  height: 3px;
  width: 100%;
}

.modal-w-medium {
  width: 512px !important;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.label_logged_message {
  font-size: 16px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.label_success_message {
  font-size: 18px;
  margin-bottom: 30px;
}

.password_label_logged_message {
  font-size: 16px;
  display: flex;
  text-align: center;
}

.my_plan_provider .input-error {
  position: relative !important;
  top: 0 !important;
}

.authorization-table tbody td:nth-child(3),
.authorization-table tbody td:nth-child(4) {
  width: 20%;
}

.medical-claims-table tbody td:nth-child(1) {
  width: 9.5rem;
}

.medical-claims-table tbody td:nth-child(2) {
  width: 41%;
}

.medical-claims-table tbody td:nth-child(3) {
  width: 40%;
}

.claim-explanation-table tbody td:nth-child(1) {
  width: 17%;
}

.claim-explanation-table tbody td:nth-child(2) {
  width: 23%;
}

.claim-explanation-table tbody td:nth-child(3) {
  width: 13%;
}

.claim-explanation-table tbody td:nth-child(4) {
  width: 28%;
}

.claim-explanation-table tbody td:nth-child(5) {
  width: 19%;
}
.approved-representative-table th,
.approved-representative-table td {
  line-height: 1.25rem !important;
  letter-spacing: 0.0175rem !important;
  padding: 0.875rem 0.75rem !important;
}
@media (max-width: 1024px) {
  .approved-representative-table th,
  .approved-representative-table td {
    padding: 0.875rem 0.5rem !important;
  }
}
.approved-representative-table th,
.approved-representative-table td,
.approved-representative-table button {
  font-size: 0.875rem !important;
}
.approved-representative-table button {
  padding: 0.38rem 0.75rem !important;
  min-width: auto;
  height: auto;
}
.table-custom-50 td:nth-child(1) {
  width: 50%;
}
.estimated_treat_cost_content h2 {
  font-family: var(--font-family-medium) !important;
  color: $dark-grayish;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: 0.035rem;
  margin-bottom: 0;
}
.estimated_treat_cost_content p {
  letter-spacing: 0.02rem;
}
.border_radius_18 {
  border-radius: 1.125rem;
}

.container-fluid {
  padding: 0 2.5rem;
  max-width: 1440px;
}

.pharmacy_claims_wrapper {
  border-radius: 0.5rem;
  border: 1px solid $sweet-cream;
  background: $snow;
  padding: 1rem;
}

.login-bg-img {
  background: url('../images/avmed_bg.svg') center;
  background-size: center;
  background-position: 0px -200px;
}

.dashboard-bg-img {
  background: url('../images/desktop.svg');
  background-size: cover;
}

/* Dashboard UI Changes */
.hero-section {
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
    align-items: center;
    border-radius: 1rem;
    background: rgba(34, 112, 102, 0.08);
  }

  .hero-img-shp {
    background: url('../images/hero-couple.svg') no-repeat top center;
    @media screen and (min-width: 768px) {
      background: url('../images/hero-couple-md.svg') no-repeat right center;
    }
  }

  .hero-img-guest-shp {
    background: url('../images/shp_wellness_guest_hero.svg') no-repeat top center;
    @media screen and (min-width: 768px) {
      background: url('../images/shp_wellness_guest_hero.svg') no-repeat right center;
    }
  }

  .hero-img-avmed {
    background: url('../images/Hero\ Image_Dashboard_Dog.svg') no-repeat top
      center;
    @media screen and (min-width: 768px) {
      background: url('../images/Hero\ Image_Dashboard_Dog.svg') no-repeat right
        center;
    }
  }

  .hero-img {
    height: 100%;
    width: 100%;
    padding-top: 22.688rem;

    @media screen and (min-width: 768px) {
      border-radius: 0 1rem 1rem 0;
      height: 23.75rem;
      background-size: contain;
      padding-left: 2.5rem;
      display: flex;
      align-items: center;
      padding-top: 0;
    }

    button {
      width: 170px;
    }
  }

  .hero-container {
    gap: 1.5rem;

    .inner-cont {
      gap: 1.5rem;

      @media screen and (min-width: 768px) {
        width: 19rem;
      }

      h2 {
        font-size: 2.25rem;

        @media screen and (min-width: 768px) {
          font-size: 2.5rem;
        }
      }
    }
  }
}

/* Grid Content UI Changes */
.grid-cont,
.plan-grid-cont {
  flex-direction: column;
  margin: 0 1rem;
  position: relative;

  @media screen and (min-width: 768px) {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    margin: 1.5rem 0;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .left-border-white,
  .bottom-border-white {
    position: absolute;
    background: $white;
    z-index: 1;
  }

  .left-border-white {
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }

  .bottom-border-white {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
  }

  .grid-col {
    border-left: 1px solid $sweet-cream;
    border-bottom: 1px solid $sweet-cream;
    gap: 0.75rem;
    padding: 2rem 0.25rem;
    text-decoration: none;
    border-top: 0;
    border-right: 0;
    font-family: var(--font-family-bold) !important;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 0.0225rem;
    line-height: 1.75rem;
    text-align: center;
    display: block;
    span {
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span svg {
      height: 2.625rem;
    }
  }
}
.plan-grid-cont {
  .grid-col {
    padding: 2rem 1.5rem;
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.virtual-border {
  border-bottom: 1px solid $sweet-cream;
}

.virtual-border-right {
  border-right: 1px solid $sweet-cream;
}

.virtual-grid-cont {
  .virtual-grid-col {
    border-left: 1px solid $sweet-cream;
    border-bottom: 1px solid $sweet-cream;
    border-top: 0;
    border-right: 0;
    position: relative;
  }
  .virtual-grid-col:nth-last-of-type(+ 2) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -0.5px;
      height: 100%;
      width: 0.5px;
      background-color: $sweet-cream;
      display: none;
      @media screen and (min-width: 426px) {
        display: block;
      }
    }
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.print-theader {
  display: none;
}
@media print {
  .print {
    min-width: none;
    visibility: visible;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact !important;
  }
  .print-theader {
    display: block;
  }
  .not-print {
    display: none !important;
  }
}

.disclaimer-title,
.disclaimer-description {
  line-height: 1.5rem !important;
}

//Page next / previous buttons
.page_button {
  background: none;
  border: none;
}

.previous_icon.page_button {
  transform: rotate(180deg);
}

.page_button:disabled {
  opacity: 0.7;
}

.dashboard-tabs-wrapper .profile-tabs,
.profile_btn_tabs_wrapper .profile-btn-group {
  width: 52%;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.profile-btn-group li:only-child .nav-link {
  border-radius: 0.5rem !important;
}
.pending-request-list-wrapper .pending-request-list {
  border-bottom: 1px solid $sweet-cream;
  padding: 1rem 0;
  gap: 1.5rem;
}
.pending-request-list-wrapper .pending-request-list:last-child {
  border-bottom: 0;
}
// /Demo Purpose
.my_plan_overview {
  .text-center.redhat_regular {
    color: $black !important;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .deductible_progressbar_member {
    margin-top: 12px;

    .text-center.redhat_bold {
      margin-bottom: 20px !important;
      line-height: 24px !important;
    }
  }

  .pt-3.pb-4 {
    padding-top: 0px !important;

    .disclaimer-title.mb-3 {
      letter-spacing: 0.32px;
      margin-bottom: 20px !important;
    }
  }
}

.member_details {
  .pt-3.pb-4 {
    padding-top: 8px !important;

    .disclaimer-title.mb-3 {
      letter-spacing: 0.32px;
      margin-bottom: 20px !important;
    }
  }
}

.member_id_card {
  .check-box-only {
    margin-left: 5% !important;
    vertical-align: middle;
  }
}
.member_details tbody tr td:nth-child(1) {
  width: 42%;
}
.member-id-card-btn .disabled_btn {
  cursor: not-allowed !important;
}

.primary_btn_proxy,
.outline-primary {
  display: flex;
  width: 11.75rem;
  padding: 0.75rem 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  margin-right: 0.62rem !important;
  margin-bottom: 1.5rem;
  background: $primary;
  font-family: var(--font-family-bold) !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
  float: left;
  border: inherit;

  &:hover,
  &:focus,
  &:active {
    background: $primary;
    border: inherit;
  }
}

.checkbox_wrapper {
  margin-top: 1.5rem;
}

.acknowledgement_wrapper {
  margin-top: 1.5rem;

  label {
    font-family: var(--font-family-regular) !important;
  }
}

button.outline-primary {
  border: 2px solid $primary;
  background: $white;
  color: $primary;

  &:hover,
  &:focus,
  &:active {
    background: $white !important;
    border: 2px solid $primary;
    color: $primary !important;
  }
}

.page_message_wrapper {
  margin-top: 2.75rem !important;
  .page_message_container {
    .message_description {
      margin-bottom: 1.5rem;
    }
    max-width: 62%;
    margin: 0 auto;
    text-align: center;
    @media screen and (max-width: 1100px) {
      max-width: 70%;
    }
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
  .message_title {
    margin: 1rem 0;
    width: 100%;
    font-family: var(--font-family-bold) !important;
    font-size: 1.25rem;
  }
}

a {
  &:focus-visible {
    outline-color: $linkblue-focus;
  }
}

//proxy_form_wrapper

.proxy_expired_wrapper {
  display: flex;
  justify-content: center;

  .expired_wrapper.text-center {
    margin-top: 0px;
  }
  .page_message_container {
    max-width: 100%;
    .message_title {
      margin-top: 1.25rem;
    }
    .icon_bg svg {
      width: 3.25rem;
      height: 3.25rem;
      font-size: 2.25rem;
      color: $font-primary;
      fill: $font-primary;
    }
    @media screen and (max-width: 1100px) {
      max-width: 100%;
    }
    .message_description {
      width: 100%;
      float: left;
    }
  }
}

.proxy_form_wrapper {
  .expired_wrapper,
  .proxy-auth-enter-details {
    h1,
    h2 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      letter-spacing: 0.0225rem;
      font-family: var(--font-family-bold) !important;
    }
    h2 {
      color: $black;
    }
    label {
      color: $dark-grayish;
      font-weight: 500;
      font-family: var(--font-family-medium) !important;
      font-size: 1rem;
      font-style: normal;
      line-height: 1.5rem;
      letter-spacing: 0.02rem;
    }
  }
  .proxy-auth-enter-details {
    h1 {
      font-size: 1.125rem;
    }
  }

  .divider-text {
    text-align: center;
    position: relative;
    background: $white;
    color: $black700;
    font-family: var(--font-family-bold) !important;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0175rem;
    &:before {
      content: '';
      position: absolute;
      border: 1px solid $sweet-cream;
      width: 45%;
      left: 0;
      top: 50%;
    }
    &:after {
      content: '';
      position: absolute;
      border: 1px solid $sweet-cream;
      width: 45%;
      right: 0;
      top: 50%;
    }
  }
  .link-expire-note {
    border-radius: 0.5rem;
    border: 1px solid #fde68a;
    background: #fffbeb;
    display: flex;
    padding: 1rem;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    color: #92400e !important;
  }
  .no-attempt-text {
    border-radius: 0.5rem;
    border: 1px solid #fecaca;
    background: #fef2f2;
    display: flex;
    padding: 1rem;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    color: #991b1b !important;
    font-size: 16px !important;
    margin-bottom: 2rem;
  }
  .error_message {
    color: $red;
    font-weight: 500;
    font-family: var(--font-family-medium) !important;
    font-size: 1rem;
    position: relative;
    top: -16px;
  }

  div.field-content span {
    width: 0.31rem !important;
    height: 0.31rem !important;
    background-color: transparent !important;
  }
  .form-input[data-testid='lastNameOhp'],
  .form-input[data-testid='memberMemberId'] {
    color: $granite-gray !important;
  }
  .button-border button {
    border-radius: 1.5rem;
    font-family: var(--font-family-bold) !important;
  }
}

.icon_bg {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: $panache;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 2.25rem;
    height: 2.25rem;
    font-size: 2.25rem;
    color: $font-primary;
    fill: $font-primary;
  }
}
.proxy_form.proxy_form_disabled {
  h1 {
    font-family: var(--font-family-bold) !important;
    text-align: center;
    font-size: 1.5rem !important;
    padding: 0px 45px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: 0.03rem;
  }
  h2 {
    font-family: var(--font-family-medium) !important;
    font-weight: 500;
    line-height: 1.75rem;
  }
  .proxy_desc {
    text-align: center;
    color: $dark-grayish !important;
  }
  .proxy-title-copy {
    margin-bottom: 1.12rem !important;
    color: #696363 !important;
    font-size: 0.875rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0175rem;
  }
  .position-relative .proxy_desc {
    text-align: left;
  }
  .proxy_desc[data-testid='acknowledgeRead'] {
    text-align: left;
    font-family: var(--font-family-medium) !important;
  }

  .secondary_btn,
  .primary_btn {
    border-radius: 30px;
  }
  .Helper_module_small {
    min-height: 90px;
    height: min-content;
    position: relative;
    top: 2px;
  }
  ._disabled {
    background-color: #fcfafa;
    border-color: #eee7e4;
  }
  .approve_wrapper {
    input,
    label {
      position: relative;
      top: -5px;
    }
  }
  .calendar_wrapper {
    position: relative;
    input {
      padding-left: 40px;
    }
    .icon_left {
      position: absolute;
      top: 53%;
      left: 0.75rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    padding: 0px 16px;
    h1 {
      padding: 0;
    }
  }
  #acknowledgement-list li {
    font-family: var(--font-family-regular), Arial, Helvetica, sans-serif;
  }
}
.proxy_form.proxy_register_wrapper {
  h1,
  h2 {
    font-family: var(--font-family-bold) !important;
    text-align: center;
    font-size: 1.5rem !important;
    padding: 0px 45px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: 0.03rem;
  }
  a {
    @extend .primary_btn;
  }
}
.expired_wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 40px;
  gap: 20px;
  padding: 20px 0;
}

.chatbot_frame {
  position: fixed;
  bottom: 30px;
  right: 20px;
  border: none;
  width: 402px;
  padding: 0;
  background: #850f0f03;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 5 !important;
}

.chatbot_icon {
  position: fixed;
  bottom: 30px;
  right: 20px;
  border: none;
  width: 150px;
  padding: 0;
  background: #850f0f03;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 5 !important;
}

.chatbot_icon button {
  border: none;
  background: transparent;
}
.chatbot_icon button:hover {
  background: transparent;
}
.chatbot_icon button:focus {
  box-shadow: unset;
  border: 4px solid rgba(5, 93, 242, 0.5);
  padding: 4px;
  outline-width: 2px;
  border-radius: 50%;
}
.chatbot_icon button:focus,
.chatbot_icon button:focus-visible {
  outline: auto;
}

.chat-bot-footer-inner button {
  border: none;
  background: transparent;
}

input[type='date']::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
  &:focus-visible {
    outline-color: $linkblue-focus;
    outline-offset: -0.015px;
  }
}
.proxy_form {
  margin-bottom: 32px;
  #commonMainFrame > div:first-child {
    max-width: 100% !important;
    width: 100% !important;
  }
  .captcha-error .input-error {
    bottom: 77px;
  }
  .mainTitle,
  .proxy_title,
  .message_title,
  h5,
  h2 {
    font-family: var(--font-family-bold) !important;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
    letter-spacing: 0.025rem;
    margin-bottom: 1.25rem !important;
  }

  .proxy_desc,
  .proxy_descOne,
  p,
  input {
    font-family: var(--font-family-regular) !important;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .position-relative .row {
    margin-top: 24px;
  }

  .proxy-title-copy {
    margin-bottom: 1.5rem !important;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  label {
    font-size: 1rem;
    font-family: var(--font-family-medium) !important;
    letter-spacing: 0.02rem;
    margin-bottom: 0.5rem;
    margin-top: 0rem !important;
  }

  .form-check-label {
    position: relative;
    top: 2px;
    font-family: var(--font-family-regular), Arial, Helvetica, sans-serif !important;
  }
  p.proxy_desc[data-testid='acknowledgeRead'] {
    font-family: var(--font-family-medium), Arial, Helvetica, sans-serif !important;
    margin-bottom: 8px !important;
  }
  .proxy_desc[data-testid='abuseDescription'] {
    font-family: var(--font-family-medium), Arial, Helvetica, sans-serif !important;
  }

  [data-testid='CSRProxy'] {
    padding-bottom: 40px;
  }
  .proxy_descOne {
    padding-bottom: 0.25rem;
    font-size: 1rem !important;
    font-style: italic !important;
  }

  #acknowledgement-list li {
    font-family: var(--font-family-regular), Arial, Helvetica, sans-serif;
  }
  //OHP css changes start
  .mb_10 {
    margin-bottom: 10px !important;
  }

  .ohp_row_wrapper .form-label {
    font-family: var(--font-family-medium), Arial, Helvetica, sans-serif !important;
  }

  .ohp_bottom_border {
    border-bottom: 1px solid $sweet-cream;
    margin-bottom: 24px;
  }
  .opt_message {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    margin-top: 1px;
  }
  .description_header {
    font-family: var(--font-family-medium), Arial, Helvetica, sans-serif;
    margin-top: 1rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }
  .proxy_desc {
    font-family: var(--font-family-medium), Arial, Helvetica, sans-serif;
    margin-top: 1rem;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }
  .proxy_desc[data-testid='pageDescription'] {
    font-family: var(--font-family-regular) !important;
  }
  .proxy_desinativeInfo,
  .proxy_disclose {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
    letter-spacing: 0.025rem;
    margin-bottom: 1.5rem;
    font-family: var(--font-family-bold), Arial, Helvetica, sans-serif;
  }
  .revoke_container {
    position: relative;
  }
  //OHP css changes End
}
.proxy_form.proxy_form_disabled.proxy_disabled_wrapper,
.proxy_form.proxy_form_disabled.proxy_revoke_wrapper,
.proxy_form.proxy_form_disabled.csr_update_wrapper .proxy_desc {
  text-align: left;
}
.proxy_form.proxy_form_disabled.csr_update_wrapper,
.proxy_form.proxy_form_disabled.proxy_revoke_wrapper {
  .mainTitle {
    font-family: var(--font-family-bold), Helvetica, Arial, sans-serif !important;
    font-size: 1.5rem;
    padding: 0px 40px;
    text-align: center;
  }
  .ohp_row_wrapper .form-input {
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
    border-radius: 0.5rem;
  }
  .ohp_row_wrapper .form-label {
    font-family: var(--font-family-medium), Arial, Helvetica, sans-serif !important;
    margin-bottom: 8px;
  }
}
button:first-letter {
  text-transform: capitalize;
}

.proxy_register_wrapper {
  a {
    display: inline-flex !important;
    border-radius: 1.5rem;
    text-decoration: none;
    line-height: unset !important;
    align-items: center;
    justify-content: center;
  }
}
.revoke_container {
  position: relative;
  .print_button {
    width: 24px;
    height: 24px;
    border: none !important;
    display: inline-block;
    cursor: pointer;
    z-index: 1;
    background: no-repeat center
      url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.469 1.5L3.5 5H2V1.5C2 .687 2.656 0 3.469 0h7.969c.374 0 .78.188 1.062.469L13.531 1.5c.281.281.469.688.469 1.063V5h-1.5V2.562L11.406 1.5H3.47zM13.75 6C14.969 6 16 7.031 16 8.25v3.5a.74.74 0 01-.75.75H13.5V15c0 .563-.469 1-1 1h-9c-.563 0-1-.438-1-1v-2.5H.75a.722.722 0 01-.75-.75v-3.5C0 7.031 1 6 2.25 6h11.5zM12 14.5v-3H4v3h8zm2.5-3.5V8.25a.76.76 0 00-.75-.75H2.25a.74.74 0 00-.75.75V11h1c0-.531.438-1 1-1h9c.531 0 1 .469 1 1h1z' fill='%23227066'/%3E%3C/svg%3E");
    position: absolute;
    right: 0;
    top: 10px;

    &:hover {
      opacity: 0.8;
    }
  }
  .revoke_authorization_wrapper {
    button {
      border-radius: 24px !important;
      font-family: var(--font-family-bold), Helvetica, Arial, sans-serif !important;
    }
  }
}
// mpc preferences styles start
.set_my_preferences {
  letter-spacing: 0.02rem;
  .hr_line {
    display: block;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .show_underline {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  section {
    border: 1px solid $sweet-cream;
    border-radius: 1rem;
  }
  textarea.form-input {
    min-height: 8rem;
    max-height: inherit;
    resize: none;
  }
  h3 {
    font-family: var(--font-family-bold) !important;
    background-color: $dark-grayish;
    border-radius: 1rem 1rem 0 0;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    color: $white;
    line-height: 1.75rem;
    letter-spacing: 0.0225rem;
    padding: 0.875rem 1.5rem;
    margin-bottom: 0px;
  }
  .gold-link {
    color: $secondary;
  }
  .go_paperless_btn {
    min-width: 144px;
  }
  .button_container {
    button {
      max-width: 100px;
      min-width: 100px;
      margin-right: 10px;
    }
    @media screen and (max-width: 767px) {
      .primary_btn {
        margin-bottom: 1rem;
      }
    }
  }
  .strength-bar-container,
  .container_bar {
    width: 92%;
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: 0.875rem;
    }
  }
  .delete_account_border {
    border-radius: 1rem;
  }

  .font_12_normal {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.02rem;
    font-family: var(--font-family-regular) !important;
    color: $font-primary;
    margin-bottom: 0px;
    margin-top: 4px;
    padding-left: 36px;
  }
  .margin_top_6 {
    margin-top: 6px;
  }
  .user_address_wrapper {
    padding: 2.5rem 2rem 2rem 2rem;
    @media (max-width: 767px) {
      padding: 1.5rem 1rem;
    }
  }
  
  label,
  label button {
    letter-spacing: 0.0175rem;
    text-align: left;
  }
  .pref_choose_options {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .pref_choose_options li {
    padding: 1rem;
  }

  .pref_choose_options label {
    font-family: var(--font-family-regular) !important;
    font-size: 1rem !important;
    letter-spacing: 0.02rem;
  }
  .lang_choose label {
    font-family: var(--font-family-bold) !important;
  }

  .border_wrapper {
    border: 2px solid $sweet-cream;
    background: $white;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top: inherit;
  }
  .notification_border {
    border: 2px solid $sweet-cream;
    border-radius: 1rem;
    padding: 0.375rem 0.75rem;
  }
  .preferences_border {
    border: 1px solid $sweet-cream;
    border-radius: 1rem;
    padding: 0.375rem 0.75rem;
  }
  .button_width {
    display: flex;
    justify-content: flex-end;
    button {
      height: fit-content;
      width: fit-content;
    }
  }
  .btn-links.darkblue-color {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 767px) {
  .profile_image_wrapper {
    h3 {
      display: none;
    }
    .border_wrapper {
      border: none;
    }
    .upload-photo {
      position: relative;
      left: -8px;
    }
    .user_address_wrapper {
      padding: 0;
    }
    .profile_name {
      font-family: var(--font-family-bold), Helvetica, Arial, sans-serif !important;
      font-size: 1.5rem;
      line-height: 1.75rem;
      letter-spacing: 0.025rem;
      color: $font-primary;
    }
  }
}
.profile_expand_collapse_btn {
  width: 150px;
  height: 40px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  > button {
    border: none !important;
    background: #ecf2f1 !important;
  }
}
.modal-overlay {
  color: $dark-grayish;
}
.phone_notify_msg_wrapper {
  height: 154px;
  overflow-x: auto;
  padding-right: 10px;
}
.modal-overlay h2 {
  font-family: var(--font-family-medium) !important;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
  letter-spacing: 0.025rem;
  margin-bottom: 0.5rem;
}
// mpc preferences styles end

#upload-photo {
  opacity: 1 !important;
  position: absolute;
  z-index: 1 !important;
  left: 0;
  background: transparent;
  color: transparent;
  border: 0;
  cursor: pointer;
  width: 100% !important;
}
input[type='file']::file-selector-button {
  border: 2px solid transparent;
  padding: 0;
  background-color: transparent;
  transition: 1s;
  color: transparent;
}
#upload-photo::-webkit-file-upload-button {
  visibility: hidden;
}
#upload-photo::before {
  content: 'Change profile photo';
  display: inline-flex;
}
.loader_contactus.loader:not(:required):before {
  z-index: 8;
}
.loader_contactus.loader:not(:required):after {
  z-index: 9;
}
// IAM landing page start

.security_banner,
.iam_landing_rht {
  border-radius: 1rem;
}
.security_banner {
  height: 100%;
  border: 1px solid $snow;
  @media (max-width: 767px) {
    border-radius: 1rem 1rem 0 0;
    height: 200px;
    width: 100%;
  }
}
.iam_landing_rht {
  background-color: $white;
  padding: 0;
  h1 {
    @extend .literata_bold;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    @media (max-width: 1024px) {
      font-size: 1.75rem;
    }
    @media (max-width: 320px) {
      font-size: 1.25rem;
    }
  }
  @media (max-width: 767px) {
    padding: 1rem;
  }
}
.logo-common {
  @media (max-width: 1024px) {
    height: 40px;
  }
  @media (max-width: 767px) {
    height: 40px;
  }
}
// IAM landing page end
.profile-terms-error .input-error {
  width: max-content !important;
  @media (max-width: 767px) {
    width: unset !important;
  }
}
.reg-mob-no-err .input-error {
  bottom: 20px;
}
.proxy_form_wrapper form input[data-testid='memberMemberId'] {
  color: $granite-gray !important;
}

//Tooltip fixes
.button_tooltip.show {
  opacity: 1 !important;
  margin-bottom: 6px;
}
.button_tooltip > .tooltip-inner {
  padding: 8px;
  background: #ffffff;
  text-align: left;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2);
  font-family: var(--font-family-regular), Arial, Helvetica, sans-serif !important;
  font-size: 13px;
  color: $black700;
  max-width: 500px;
  opacity: 1 !important;

  @media (max-width: 520px) {
    max-width: 300px;
  }
}
.button_tooltip.bs-tooltip-top .tooltip-arrow::before {
  top: 0px;
  border-top-color: #fff;
}

.error-border.form-input:focus-visible {
  outline: 0;
  outline-color: $red;
  box-shadow: 0px 0px 1px 0.25rem rgba(237, 7, 7, 0.3);
  border-color: $red;
}

.pdf_container {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  flex-wrap: wrap;
  font-family: var(--font-family-bold), Helvetica, Arial, sans-serif;
  font-size: 1rem;

  a {
    text-decoration: none !important;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    &:hover,
    &:active {
      text-decoration: underline !important;
      text-underline-position: under;
    }
  }
  button {
    background: none;
    border: none;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    &:hover,
    &:active {
      text-decoration: underline !important;
      text-underline-position: under;
    }
  }
  .back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 16px;
    padding-left: 0px;
    svg {
      transform: rotate(180deg);
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 16px;
  }
}

.pdf_container_mobile {
  display: block;

  .action-buttons {
    float: right;
    clear: both;
  }
}

[data-role='errorMessage'] {
  margin-top: 16px !important;
}
.error_btn_height {
  position: relative;
  top: 16px;
}
.plandocuments_provider {
  /* Grid Content UI Changes */
  .grid-cont,
  .plan-grid-cont {
    flex-direction: column;
    margin: 0 1rem;
    position: relative;
    h2 {
      font-family: var(--font-family-medium) !important;
      font-size: 1.5rem;
      font-style: normal;
      line-height: 1.5rem;
      letter-spacing: 0.02rem;
      margin-bottom: 16px;
    }
    p {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.02rem;
      font-family: var(--font-family-regular) !important;
      margin-bottom: 24px;
    }

    @media screen and (min-width: 768px) {
      display: grid !important;
      grid-template-columns: repeat(1, 1fr);
      margin: 1.5rem 0;
    }

    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .grid-col {
      gap: 0.75rem;
      padding: 0;
      border: none;
      text-decoration: none;
      border-top: 0;
      border-right: 0;
      font-family: var(--font-family-bold) !important;
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: 0.0225rem;
      line-height: 1.75rem;
      text-align: center;
      max-width: fit-content;
      @media (max-width: 767px) {
        text-align: left;
      }
      span {
        padding-left: 10px;
        height: auto;
        width: auto;
        svg {
          height: auto;
        }
      }
    }
  }

  p.pdf_size {
    font-size: 0.875rem;
    color: $suva-grey;
    margin: 0;
    span {
      padding-left: 3px;
    }
  }

  .member_wrapper_border {
    width: 100%;
    float: left;
    border-bottom: 1px solid $sweet-cream;
    margin: 1.125rem 0px 2rem 0px;
  }
}

.font-size-14 {
  font-size: 14px;
}

.accordion-table-disabled .accordion-button {
  visibility: hidden;
}

.border_0 {
  &:hover {
    border-left: 1px solid $sweet-cream !important;
    border-bottom: 1px solid $sweet-cream !important;
  }
}

.curved-box {
  border: 1px solid rgb(238, 231, 228, 1);
  border-radius: 16px;
  width: 465px;
  margin: 16;
}

.curved-box-single-box {
  border: 1px solid rgb(238, 231, 228, 1);
  border-radius: 16px;
  width: 541px;
  margin-top: 36px;
  height: 95%;
  @media screen and (max-width: 768px) {
    overflow: scroll;
  }
}
.labels-list {
  list-style-type: none; /* Remove list bullets */
  padding-left: 0; /* Remove default padding */
}

.virtual-visit-selection {
  width: 1000px !important;
  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }

  @media screen and (max-width: 768px) {
    .curved-box {
      width: 100%;
    }
  }
}

.virtual-visit-box {
  width: 590px !important;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }
}

.virtual-visit-cost-style {
  display: inline-block;
}

.virtual-visit-icon-style {
  svg {
    width: 460px;
    height: 200px;
    object-fit: cover;
    @media screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
    }
  }
}

.virtual-visit-badge-style {
  width: 129px;
  height: 32px;
}

.virtual-visit-button-style {
  align-self: flex-end;
  height: 40px;
}

.custom-list {
  list-style-type: disc;
  padding-left: 20px; /* Adjust as needed */
}

.custom-list li {
  display: list-item;
  margin-bottom: 10px; /* Adjust as needed for spacing between items */
}

.cursor-pointer {
  cursor: pointer;
}

.italic-style {
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .caretile-border-bottom {
    border-bottom: 1px solid $sweet-cream;
  }

  .search-pharmacy {
    .search-content {
      border: none;
      img {
        border-radius: 1rem;
        height: 240px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .caretile-border-bottom {
    border-bottom: none;
  }

  .search-pharmacy {
    .search-content {
      border-radius: 1rem;
      border: 1px solid $sweet-cream;
      img {
        border-radius: 1rem 0 0 1rem;
        height: 100%;
      }
    }
  }
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-first-child_radius.table-custom-style
  tbody
  tr:first-child
  > td:first-child {
  border-top-left-radius: 1.063rem;
}
.table-first-child_radius.table-custom-style
  tbody
  tr:first-child
  > td:last-child {
  border-top-right-radius: 1.063rem;
}

.care-tile {
  width: 48px;
  height: 48px;
}

.pharmacy-care-tile {
  width: 40px;
  height: 36px;
}

.flex-display {
  display: flex;
}

.ml-2 {
  margin-left: 1rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.ml-4 {
  margin-left: 0.65rem;
}

.bgColor-snow {
  background-color: $snow !important;
}
